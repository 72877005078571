<template>
  <div
    class="f-radio-button"
    :class="{
      'is-full-width': isFullWidth,
      'is-loading': isLoading,
      'is-animation-disabled': isAnimationDisabled,
      [color]: color,
    }"
  >
    <el-radio-group
      :model-value="modelValue"
      v-bind="$attrs"
      @change="onModelValueUpdate"
    >
      <el-radio-button
        v-for="(option, index) in options"
        :key="option.value"
        :index="index"
        :label="option.value"
        :model-value="option.value"
        :class="{
          'is-selected': modelValue === option.value || (option.value === 'ALL' && modelValue === null),
          'is-secondary': isSecondary,
        }"
        @mouseenter="checkHover(option.value, modelValue, true)"
        @mouseleave="checkHover(option.value, modelValue, false)"
      >
        <slot :text="option.text" :value="option.value" :logo="option.logo">
          {{ option?.text }}
        </slot>
      </el-radio-button>
    </el-radio-group>
  </div>
</template>

<script setup>
import 'element-plus/theme-chalk/el-radio-button.css';
import 'element-plus/theme-chalk/el-radio-group.css';
import 'element-plus/theme-chalk/el-radio.css';
import { ElRadioButton, ElRadioGroup } from 'element-plus';

const props = defineProps({
  options: {
    type: Array,
    default: () => [],
  },
  isFullWidth: {
    type: Boolean,
    default: false,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  isSecondary: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: [String, Boolean],
    default: '',
  },
  color: {
    type: String,
    default: 'primary',
    validate: (value) => ['black', 'primary'].includes(value),
  },
  isAnimationDisabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue', 'updateHover']);

const onModelValueUpdate = (newValue) => {
  const VAL = props.options.find((el) => el.value === newValue);

  emit('update:modelValue', VAL?.value);
};

const checkHover = (val, modelValue, isHover) => {
  emit('updateHover', val, modelValue, isHover);
};
</script>

<style scoped lang="scss">
.is-full-width {
  :deep(.el-radio-group) {
    width: 100%;
    display: flex;
  }
  :deep(.el-radio-button) {
    flex: 1;
  }
}

:deep(.el-radio-group) {
  flex-wrap: nowrap;
}

.f-radio-button {
  :deep(.el-radio-button) {
    &:focus {
      border: none !important;
      box-shadow: none !important;
    }
    .el-radio-button__original-radio:focus-visible {
      & + .el-radio-button__inner {
        @include focus-visible-style;
        border-color: transparent !important;
      }
    }
    .el-radio-button__inner {
      font-size: 13px;
      margin-right: -1px;
      height: 36px;
      display: flex;
      align-items: center;
      padding: 0 20px;
      box-shadow: none !important;
      border: 1px solid #DADADA;
      @include transition-base(background);
    }
  }
}

.f-radio-button.black {
  :deep(.el-radio-button) {
    &.is-selected {
      .el-radio-button__inner {
        background: var(--color-black);
        color: #ffffff;
      }
    }
    .el-radio-button__inner {
      background: #E5E5E5;
      color: var(--color-black);
    }
  }
}

.f-radio-button.primary {
  :deep(.el-radio-button) {
    .el-radio-button__inner {
      color: var(--color-black);

      &:hover {
        background: #F2F2F2;
      }
    }

    &.is-selected {
      .el-radio-button__inner {
        background: #FBF3EC;
        border: 1px solid var(--color-primary);
        z-index: 3;
      }
    }
  }
}

.f-radio-button {
  opacity: 1;
  // @include transition-base(opacity);

  &.is-loading {
    opacity: 0.5;
  }
}

.is-animation-disabled {
  :deep(.el-radio-button__inner) {
    transition: initial;
  }
}

.f-radio-button {
  .el-radio-button.is-secondary {
    :deep(.el-radio-button__inner) {
      height: 32px;
      background: var(--color-black-004);
      border-radius: 28px;
      border: unset;
      padding: 0 12px;
      margin-right: 4px;
      display: flex;
      align-items: center;
      @include transition-base('background, color');
      &:focus-visible {
        @include focus-visible-style;
        border-radius: 28px !important;
      }

      &:hover {
        background: var(--color-black-008);
      }
    }

    &.is-selected {
      :deep(.el-radio-button__inner) {
        border: unset;
        background: var(--color-primary-01);
        color: var(--color-primary);
      }
    }
  }
}
</style>
