<template>
  <div
    ref="notificationRef"
    class="notification"
    :class="{
      'has-two-lines': hasTwoLines,
    }"
    @mouseenter="isClosable = false"
    @mouseleave="onMouseLeave"
    @click="onClose"
  >
    <div class="icon">
      <AppIcon :key="iconName" :name="iconName" size="18px" />
    </div>
    <div class="info">
      <AppText ref="message" :line-height="1.5">
        {{ message }}
      </AppText>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useElementSize } from '@vueuse/core';

const emit = defineEmits(['close']);

const props = defineProps({
  duration: {
    type: [Number, String],
    default: 2000,
  },
  type: {
    type: String,
    required: true,
    default: 'success',
    validator: (value) => ['success', 'warning', 'error'].includes(value),
  },
  message: {
    type: String,
    default: '',
  },
});

const activeTimeout = ref(-1);

const initTimeout = (val) => {
  window.clearTimeout(activeTimeout.value);

  const timeout = Number(val);

  activeTimeout.value = window.setTimeout(() => {
    if (isClosable.value) {
      onClose();
    }
  }, timeout);
};

const onClose = () => {
  emit('close');
};

initTimeout(props.duration);

const iconName = computed(() => (props.type ? `notification-${props.type}` : 'notification-success'));

const isClosable = ref(true);

const onMouseLeave = () => {
  isClosable.value = true;
  initTimeout(1000);
};

const notificationRef = ref(null);
const { height } = useElementSize(notificationRef);
const hasTwoLines = computed(() => (height.value > 36));
</script>

<style scoped lang="scss">
.notification {
  cursor: pointer;
  width: auto;
  max-width: 480px;
  color: var(--color-white);
  background: #2B1C2E;
  border-radius: 48px;
  padding: 8px 16px 8px 8px;
  margin: 0 12px;
  gap: 12px;

  display: flex;
  align-items: center;

  &.has-two-lines {
    padding: 8px 20px;
    gap: 16px;
  }

  .icon {
    width: 18px;
    height: 18px;
  }
  .info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
</style>
