<template>
  <el-tabs
    :key="options"
    :model-value="modelValue"
    v-bind="$attrs"
    :class="{
      'is-ftabs-left': $attrs['tab-position'] === 'left',
      'has-colored-titles': hasColoredTitles,
    }"
    @tab-click="onHandleClick"
  >
    <el-tab-pane
      v-for="option in options"
      :key="option.value"
      :label="option.text"
      :name="option.value"
      :disabled="option.disabled"
    >
      <template #label>
        <AppTooltip
          :is-enabled="option.disabled === true && Boolean(option.tooltipMessage)"
          text-align="left"
          :width="option.tooltipWidth || '182px'"
          :cursor="option.disabled === true ? 'default' : $attrs['tab-position'] === 'left' ? 'default' : 'pointer'"
        >
          <template #text>
            {{ option.tooltipMessage }}
          </template>
          <div
            class="custom-label"
            :class="{
              'is-custom-active': option.value === modelValue,
            }"
          >
            <slot name="label" :item="option">
              {{ option.text }}
            </slot>
          </div>
        </AppTooltip>
      </template>
    </el-tab-pane>
  </el-tabs>
</template>

<script setup>
import 'element-plus/theme-chalk/el-tab-pane.css';
import 'element-plus/theme-chalk/el-tabs.css';
import { ElTabPane, ElTabs } from 'element-plus';

import AppTooltip from '@/components/AppTooltip.vue';

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
  options: {
    type: Array,
    default: () => [],
  },
  disableTabChange: {
    type: Boolean,
    default: false,
  },
  hasColoredTitles: {
    type: Boolean,
    default: false,
  },
  height: {
    type: String,
    default: '40px',
  },
});

const emit = defineEmits(['update:modelValue']);

const onHandleClick = (tab) => {
  if (!props.disableTabChange) {
    emit('update:modelValue', tab.props.name);
  }
};
</script>

<style scoped lang="scss">
.has-colored-titles {
  :deep(.el-tabs__item) {
    &.is-active {
      color: var(--color-primary);
    }
  }
}

:deep(.el-tabs__item) {
  padding: 0 !important;
  margin-right: 30px !important;
  text-transform: uppercase;
  border-bottom: 1px solid;
  border-color: rgba(0, 0, 0, 0);
  transition: border-color 0.4s, color 0.2s;
  font-size: 13px;
  border-bottom-width: 3px;
  height: v-bind('height');
  line-height: v-bind('height');
  @include font-medium;

  &.is-active {
    border-bottom: 3px solid var(--color-primary);
  }

  &.is-disabled {
    opacity: 0.3;
  }

  &:focus-visible {
    @include focus-visible-style;
    box-shadow: none;
    outline-offset: -2px;
  }
}
:deep(.el-tabs__active-bar) {
  display: none !important;
}

:deep(.el-tabs__nav-wrap::after) {
  height: 1px;
}

.is-ftabs-left {
  :deep(.el-tabs__item) {
    border-bottom: unset;
    color: var(--color-black-05);
    text-transform: initial;
    text-align: left !important;
    padding-left: 20px !important;
    @include font-medium;
    @include transition-base(color);

    position: relative;

    &.is-left {
      justify-content: flex-start;
      cursor: initial;

      :deep(.tooltip) {
        cursor: initial;
      }
    }

    &:hover {
      border-bottom: unset !important;
    }

    .custom-label {
      &::before {
        box-sizing: border-box;
        content: '';
        width: 10px;
        height: 10px;
        border-radius: 50%;
        position: absolute;
        left: 0px;
        top: calc(50% - 5px);

        border: 1px solid;
        background: #ffffff;
        border-color: var(--color-black-01);
        @include transition-base;
      }
      &.is-custom-active {
        color: var(--color-black);
        border-bottom: unset;
        &::before {
          background: var(--color-black);
          border-color: rgba(0, 0, 0, 1);
        }
      }
    }
    &.is-active {
      color: var(--color-black-05);
      border-bottom: unset;
      &::before {
        background: #fff;
      }
    }
  }

  :deep(.el-tabs__nav-wrap::after) {
    width: 0 !important;
  }
}

</style>
