<template>
  <Teleport to="body">
    <div class="notify-container">
      <transition-group name="anim">
        <NotificationItem
          v-for="notification in notifications"
          :key="notification.id"
          :type="notification.config.color"
          :duration="notification.config.duration"
          :title="notification.title"
          :message="notification.text"
          @close="onClose(notification.id)"
        />
      </transition-group>
    </div>
  </Teleport>
</template>

<script>
import { useNotifications } from '@/composables/useNotifications';

import NotificationItem from './NotificationItem.vue';

export default {
  components: {
    NotificationItem,
  },
  setup() {
    const { notifications, removeNotification } = useNotifications();

    const onSnackbarInput = (id, value) => {
      if (value === false) {
        removeNotification(id);
      }
    };

    const onClose = (id) => {
      removeNotification(id);
    };

    return {
      notifications,
      onSnackbarInput,
      onClose,
    };
  },
};
</script>

<style lang="scss" scoped>
$marginTop: 22px;

.notify-container {
  width: 40%;
  position: fixed;
  top: 0;
  left: 30%;

  @include flex-center;
  flex-direction: column;
  z-index: 10000;

  :deep(.notification) {
    margin-top: $marginTop;
  }
}

.anim-enter-active {
  animation: anim-in 0.2s cubic-bezier(0, 0.27, 0.29, 1.01);
}
.anim-leave-active {
  animation: anim-in 0.2s ease-out reverse;
}

@keyframes anim-in {
  0% {
    margin-top: -$marginTop;
    opacity: 0;
  }
  100% {
    margin-top: $marginTop;
    opacity: 1;
  }
}
</style>
