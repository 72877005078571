<template>
  <AppIcon
    name="arrow-bracket"
    size="18px"
    :opacity="0.4"
    :class="isOpen ? 'arrow-active' : 'arrow-inactive'"
  />
</template>

<script>
export default {
  name: 'DropdownArrow',
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.arrow-inactive {
  transition: 0.2s transform;
  transform: rotate(180deg);
  @include transition-base(transform);
}

.arrow-active {
  transition: 0.2s transform;
  transform: rotate(0deg);
  @include transition-base(transform);
}
</style>
