<template>
  <ElUpload
    ref="uploadComponent"
    v-bind="$attrs"
    v-model:file-list="items"
    class="f-upload"
    :auto-upload="false"
    :limit="limit"
    @change="onChange"
    @exceed="onExceed"
  >
    <template v-for="(el, slot) of $slots" #[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>

    <template #file="{ file }">
      <div class="file-wrapper" @click="onRemove(file)">
        <AppText color="var(--color-black)">
          {{ getFileName(file.name) }}
        </AppText>
        <AppIcon name="x-close-small" size="18px" class="close-icon" />
      </div>
    </template>
  </ElUpload>
</template>

<script setup>
import { ref } from 'vue';
import 'element-plus/theme-chalk/el-upload.css';
import { ElUpload } from 'element-plus';

import { i18n } from '@/plugins/localization';
import { useNotifications } from '@/composables/useNotifications';
import { truncateString } from '@/utils/functions';

const uploadComponent = ref(null);

const props = defineProps({
  limit: {
    type: Number,
    default: 5,
  },
  maxSize: {
    type: Number,
    default: 10,
  },
});

const { t } = i18n.global;

const items = defineModel({ type: Array, default: [] });

const onChange = (uploadedFile, files) => {
  const fileSize = (uploadedFile.size / (1024 * 1024)).toFixed(2);
  const currentFile = files.find((file) => file.uid === uploadedFile.uid);
  if (fileSize > props.maxSize && currentFile) {
    addNotification({
      text: t('component.upload.maxSize', { fileName: uploadedFile.name, maxSize: props.maxSize }),
      config: { color: 'warning', duration: 3000 },
    });
    uploadComponent.value.handleRemove(currentFile);
  } else {
    items.value = files;
  }
};
const { addNotification } = useNotifications();
const onExceed = () => {
  addNotification({
    text: t('component.upload.limit', { limit: props.limit }),
    config: { color: 'warning' },
  });
};
const getFileName = (fileName) => {
  const splittedName = fileName.split('.');
  const extension = splittedName[splittedName.length - 1];
  const nameWithoutExtension = splittedName.slice(0, splittedName.length - 1).join();

  const truncatedName = truncateString(nameWithoutExtension, { maxLength: 20 });

  return `${truncatedName}.${extension}`;
};

const onRemove = (currentFile) => {
  uploadComponent.value.handleRemove(currentFile);
};
</script>

<style scoped lang="scss">
:deep(.el-upload) {
  opacity: 0.4;
  @include transition-base(opacity);

  &:hover, &:active{
    border-color: unset;
    color: unset;
    color: black;
  }

  &:hover, &:active, &:focus{
    .el-upload-dragger {
      border: 2px var(--color-black-025) dashed;
      color: black;
    }
  }

  &:hover {
    opacity: 1;
  }
}
:deep(.el-upload--text) {
  margin-bottom: 20px;
}
:deep(.el-upload-dragger) {
  padding: 0;
  border: 2px var(--color-black-025) dashed;
  @include transition-base(border);
}
:deep(.el-upload-list) {
  margin-top: 0;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
:deep(.el-upload-list__item) {
  width: auto;
  @include transition-base();
}
:deep(.el-upload-list__item-name) {
  padding: 10px 12px 10px 0;
  color: var(--el-text-color-regular) !important;
  @include transition-base();
}
:deep(.el-upload-list__item-info) {
  width: calc(100% - 10px);
}

.file-wrapper {
  border-radius: 4px;
  background: #F2F2F2;
  padding: 9px 12px;

  display: flex;
  align-items: center;
  .close-icon {
    margin-left: 4px;
    opacity: 0.4;
    cursor: pointer;
    position: relative;
    top: 1px;
    @include transition-base(opacity);

    &:hover {
      opacity: 1;
    }
  }
}
</style>
