<template>
  <img
    v-if="isImgTag"
    ref="img"
    v-bind="attrs"
    class="elem"
    :alt="name"
    :class="{ 'appears-elem': isLoaded }"
    draggable="false"
  >
  <svg
    v-else
    v-bind="attrs"
    class="elem"
    :alt="name"
    :class="{ 'appears-elem': isLoaded }"
  />
</template>

<script>
import 'external-svg-loader';
import {
  computed, onMounted, reactive, ref, watch,
} from 'vue';

export default {
  name: 'AppIcon',
  props: {
    name: {
      type: String,
      default: '',
    },
    folder: {
      type: String,
      default: 'icons',
    },
    ariaLabel: {
      type: String,
      default: '',
    },
    width: {
      type: [String, Number],
      default: null,
    },
    height: {
      type: [String, Number],
      default: null,
    },
    size: {
      type: [String, Number],
      default: '',
    },
    display: {
      type: String,
      default: 'block',
    },
    opacity: {
      type: [String, Number],
      default: 'unset',
    },
    fill: {
      type: String,
      default: '',
    },
    stroke: {
      type: String,
      default: '',
    },
    isHovered: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '',
    },
    isImgTag: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['load'],
  setup(props, { emit }) {
    const setStyles = () => {
      const styles = {};

      if (props.height) {
        styles['--icon-height'] = props.height;
      }
      if (props.width) {
        styles['--icon-width'] = props.width;
      }
      if (props.size) {
        styles['--icon-width'] = props.size;
        styles['--icon-height'] = props.size;
      }
      if (props.display) {
        styles['--icon-display'] = props.display;
      }
      if (props.opacity) {
        styles['--icon-opacity'] = props.opacity;
      }
      if (props.fill) {
        styles['--icon-fill'] = props.fill;
      }
      if (props.stroke) {
        styles['--icon-stroke'] = props.stroke;
      }
      if (props.color) {
        styles['--icon-color'] = props.color;
      }
      return styles;
    };
    const styles = reactive(setStyles());

    const path = ref('');

    try {
      // eslint-disable-next-line
      path.value = require(`@/assets/${props.folder}/${props.name}.svg`);
    } catch (e) {
      // eslint-disable-next-line
      path.value = require('@/assets/icons/warning.svg');
      if (process.env.NODE_ENV === 'development') {
        console.error(`Wrong path for Icon.vue component.\n${e.message}.`);
      }
    }

    const attrs = reactive({
      class: {
        'is-hovered': props.isHovered,
        'has-color': Boolean(props.color),
        'has-fill': Boolean(props.fill),
        icon: true,
      },
      style: styles,
      [props.isImgTag ? 'src' : 'data-src']: path,
      'aria-label': props.ariaLabel,
    });

    const isLoaded = ref(false);

    onMounted(() => {
      isLoaded.value = true;
      emit('load');
    });

    const img = ref({});

    watch(computed(() => img.value.naturalHeight), (val) => {
      if (val) {
        isLoaded.value = true;
      }
    });

    return {
      attrs,
      isLoaded,

      img,
    };
  },
};
</script>

<style scoped lang="scss">
.icon {
  flex-shrink: 0;

  width: var(--icon-width);
  height: var(--icon-height);
  opacity: var(--icon-opacity);

  :deep(svg) {
    display: var(--icon-display);
    height: var(--icon-height);
    width: var(--icon-width);
    opacity: var(--icon-opacity);
  }

  &.has-color {
    &:deep(path), &:deep(g), &:deep(rect) {
      stroke: var(--icon-color);
    }
  }
  &.has-fill {
    :deep(path) {
      fill: var(--icon-fill);
    }
  }
}

.is-hovered {
  &:hover {
    cursor: pointer;
  }
}

// .elem {
//   opacity: 0;
//   @include transition-base(opacity);
// }
// .appears-elem {
//   opacity: 1;
// }
</style>
