<template>
  <template v-if="isPlainText">
    {{ formattedDate }}
  </template>
  <AppText
    v-else
    v-bind="$attrs"
    class="date"
  >
    {{ formattedDate }}
  </AppText>
</template>

<script>
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { computed } from 'vue';

import { useAdmin } from '@/composables/useAdmin';
import { useSettings } from '@/composables/useSettings';
import { useToken } from '@/composables/useToken';

export default {
  name: 'DateFormatter',
  inheritAttrs: true,
  props: {
    date: {
      type: [String, Date],
      default: '',
    },
    format: {
      type: String,
      default: null,
    },
    isUtc: {
      type: Boolean,
      default: false,
    },
    isPlainText: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { isAdminRole } = useToken();

    const { dateFormat, timezoneCode } = isAdminRole.value ? useAdmin() : useSettings();
    const currentDateFormat = computed(() => (props.format ? props.format : dateFormat.value));

    const dateFormatter = (date) => {
      dayjs.extend(utc);
      dayjs.extend(timezone);

      if (props.isUtc) {
        return dayjs.utc(date);
      }

      try {
        return dayjs(date).tz(timezoneCode.value);
      } catch (error) {
        return dayjs(date);
      }
    };

    const formattedDate = computed(() => (props.date ? dateFormatter(props.date).format(currentDateFormat.value) : '-'));

    return {
      formattedDate,
    };
  },
};
</script>
