<template>
  <AppText v-bind="$attrs">
    {{ number }}
  </AppText>
</template>

<script>
import { computed } from 'vue';

import { formatNumber } from '@/utils/numberFormatter';
import { numberTypes } from '@/common/data';

export default {
  name: 'NumberFormatter',
  props: {
    value: {
      type: Number,
      default: 0,
    },
    format: {
      type: String,
      default: '',
      validate: (value) => ['comma', 'normal'].includes(value),
    },
    type: {
      type: String,
      default: '',
      validate: (value) => Object.values(numberTypes).includes(value),
    },
    isRaw: {
      type: Boolean,
      default: false,
    },
    isStat: {
      type: Boolean,
      default: false,
    },
    isInr: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const number = computed(() => formatNumber({
      value: props.value,
      format: props.format,
      numberType: props.type,
      isRaw: props.isRaw,
      isStat: props.isStat,
      isInr: props.isInr,
    }));

    return { number };
  },
};
</script>
