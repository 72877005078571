/* eslint-disable import/order */

import FButton from '@/components/ElementUI/FButton.vue';
import FDatePicker from '@/components/ElementUI/DatePicker.vue';
import FDateRangeDropdown from '@/components/ElementUI/DateRangeDropdown/index';
import FCheckbox from '@/components/ElementUI/FCheckbox.vue';
import FInput from '@/components/ElementUI/FInput.vue';
import FRadio from '@/components/ElementUI/FRadio.vue';
import FRadioButton from '@/components/ElementUI/RadioButton.vue';
import FSelect from '@/components/ElementUI/FSelect.vue';
import FSlider from '@/components/ElementUI/FSlider.vue';
import FSwitch from '@/components/ElementUI/FSwitch.vue';

// import FTable from '@/components/ElementUI/Table';
import FCollapce from '@/components/ElementUI/FCollapse.vue';
import FCollapceItem from '@/components/ElementUI/CollapseItem.vue';
import FTabs from '@/components/ElementUI/FTabs.vue';
import FCard from '@/components/ElementUI/FCard.vue';
import FSpacer from '@/components/ElementUI/FSpacer.vue';

import FDropdown from './FDropdown.vue';
import FImage from './FImage.vue';
import FSkeleton from './FSkeleton.vue';
import FUpload from './FUpload.vue';

export default {
  FButton,
  FDatePicker,
  FDateRangeDropdown,
  FInput,
  FRadioButton,
  FRadio,
  FSelect,
  FSwitch,
  FDropdown,
  // FTable,
  FTabs,
  FSpacer,
  FCheckbox,
  FCard,
  FImage,
  FSkeleton,
  FCollapce,
  FCollapceItem,
  FSlider,
  FUpload,
};
